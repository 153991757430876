body {
  background-color: rgb(234, 234, 234) !important;
}

.ui.inverted.top.fixed.menu {
  background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115) 0%,
    rgb(33, 138, 174) 69%,
    rgb(32, 167, 172) 89%
  ) !important;
}

/*home page styles*/

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115) 0%,
    rgb(33, 138, 174) 69%,
    rgb(32, 167, 172) 89%
  ) !important;
  height: 100vh;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

.ui.container {
  width: 90%;
}

.topHeading {
  color: royalblue;
  font-weight: bold;
}
.middleHeading {
  color: darkgreen;
  font-weight: bold;
}

.bottomHeading {
  color: darkgoldenrod;
  font-weight: bold;
}

.topRow {
  color: royalblue;
}
.middleRow {
  color: darkgreen;
}

.bottomRow {
  color: darkgoldenrod;
}

.scrollable_table {
  height: 290px;
  overflow-y: scroll; /* or auto */
}

/*
.ui.fluid.container {
  margin-left: 2.5em !important;
}
.ui.grid {
  margin-right: 2.5em !important;
}
*/
/*end home page styles*/

.app-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}
.time-line-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 500px;
  margin: 10px;
}

pdflogo {
  width: 74;
  height: 66;
  margin-left: "auto";
  margin-right: "auto";
}
