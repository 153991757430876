.mygrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  row-gap: 5px;
  column-gap: 15px;
}

.searchGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  row-gap: 5px;
  column-gap: 15px;
  border: 1px solid teal;
  padding: 15px;
}

.resultOptions {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-column-gap: 10rem;
  grid-row-gap: 0.5rem;
  justify-items: center;
  
}
.resultOptions__toggle{
  display: flex;
  justify-content: space-around;
}
.resultOptions__toggle_heading{
  font-size: 16px;
  text-decoration: underline;
  color: rgb(32, 42, 104);
  padding-right: 10px;
}
.toggle_heading_all{
  color: orangered;
}
.mygridcell {
  margin: 0.5rem 0 !important;
}

.mytextarea {
  border: none;
  /*background-color: beige;*/
  resize: none;
}

.gridheader {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}
