.documentCustomerContacts {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.dcc_col1 {
  grid-column: 1/2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid black;
}

.dcc_col2 {
  grid-column: 2/3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid black;
}

.dcc_col3 {
  grid-column: 3/4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid black;
}

.dcc_col4 {
  grid-column: 4/-1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid black;
}

.dcc_header {
  font-weight: 600;
  font-size: medium;
  border-bottom: 1px solid black;
}

.dcc_value {
  font-size: medium;
  border-right: 1px solid black;
  border-left: 1px solid black;
  height: 40px;
  padding: 3px;
}
