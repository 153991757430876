.salesOrderDetailsCopier {
  display: grid;
  grid-template-columns: 5fr 1fr 5fr;
  justify-items: center;
}

.sodc_scrollable {
  height: 290px;
  overflow-y: scroll; /* or auto */
}
