.mygrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  row-gap: 5px;
  column-gap: 15px;
}

select {
  border: 1px solid black;
  border-radius: 4px;
  padding: 5px;
  margin: 5px 0;
  font: 14px Georgia, Serif;
  overflow: hidden;
  resize: none;
  height: 70%;
  width: 70%;
  text-align: left;
  text-align: "center";
  background-color: rgb(227, 241, 245);
}

input {
  border: 1px solid black;
  border-radius: 4px;
  padding: 5px;
  margin: 5px 0;
  font: 14px Georgia, Serif;
  overflow: hidden;
  height: 70%;
  width: 70%;
  text-align: "center";
  background-color: rgb(227, 241, 245);
}
