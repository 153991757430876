.documentSoldToShipTo {
}

.soldToShipToGrid {
  display: grid;
  grid-template-columns: 1.5fr 2fr 1.5fr;
}

.ststDataSection {
  margin-top: 1px !important;
  margin-bottom: 20px !important;
  padding: 10px;
  border: 1px solid #000;
  line-height: 2rem;
}

.ststTitle {
  margin-bottom: 10px;
  font-weight: 500 !important;
  font-size: large;
  border-bottom: 1px solid #000;
}

.ststSearch {
  margin-top: 1px !important;
  margin-bottom: 20px !important;
  padding: 10px;
  line-height: 2rem;
}

.ststTextArea {
  text-align: left !important;
  overflow: hidden !important;
  resize: none !important;
  border: none;
  background-color: rgb(227, 241, 245);
}
.longTA {
  width: 100% !important;
  height: 100px !important;
}
.shortTA {
  width: 80% !important;
  height: 30px !important;
}

.wrapNewLines {
  white-space: pre-line;
  line-height: 1.3rem !important;
  padding: 5px !important;
  margin: 0px;
}
