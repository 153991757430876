.didGridTableButtons {
  margin-top: 15px;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
}

.didTaxSelector {
  display: flex;
  justify-content: space-between;
}
.purchaseDocumentItemDetails {
  display: grid;

  grid-template-columns: 0.5fr 2fr 3fr 1fr 1fr 1fr 1fr;
}

.grnItemDetails {
  display: grid;
  grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 3fr;
}

/* Add border bottom to all items */
.didCell {
  padding: 10px;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Add right border to every second item 
.didCell:nth-child(odd) {
  border-right: 1px solid black;
}

.didCell:nth-child(even) {
  border-right: 1px solid black;
}

.rowspan2 {
  grid-row: span 2;
  border-bottom: 2px solid orangered;
  padding-bottom: 2px;
}

.didRowCell {
  grid-column: span 5;
  border-bottom: 2px solid orangered;
  border-right: 1px solid black;
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding-bottom: 2px;
}
.didHeader {
  font-weight: 600;
  border-bottom: 2px solid orangered;
  background-color: lightgrey;
}

.didRightAlignedCell {
  display: flex;
  justify-content: center;
  align-content: center;
}

*/
