.hlogo {
  display: flex !important;
  justify-content: space-between;
}

.hlogo__imagestyle {
  width: 300px !important;
  height: 60px !important;
}

.hlogo__documentNumber {
  align-self: flex-start;
  display: inline !important;
  float: right !important;
  text-transform: uppercase !important;
  font: 20px Helvetica, Sans-Serif !important;
  color: rgb(117, 8, 8) !important;
  font-weight: 400 !important;
  letter-spacing: 5px !important;
  padding: 8px !important;
  margin-right: 30px !important;
}
