@import "documentHeaderLogo.css";
@import "documentHeaderAndAddress.css";
@import "documentSoldToShipTo.css";
@import "documentCustomerContacts.css";
@import "documentItemDetails.css";
@import "documentTaxes.css";
@import "purchaseOrderDetailsCopier.css";

.invoiceDocument {
  margin: 0 auto !important;
  margin-left: 5px !important;
  width: 85% !important;
  border: 2px solid #1b972d !important;
  background-color: #fff !important;
  border-collapse: collapse !important;
  border-radius: 15px !important;
  padding: 30px !important;
  display: grid;
  /*grid-template-rows: 8vh 20vh 20vh 1px 5vh 10vh 50vh 50vh min-content 20vh;*/
  grid-gap: 0.5em;
}

#textarea {
  border: 0;
  font: 14px Georgia, Serif;
  overflow: hidden;
  resize: none;
  height: 100%;
  width: 100%;
  text-align: left;
  background-color: rgb(227, 241, 245);
}

p {
  padding: 2px;
}
