#img {
  width: 200px;
}

#textarea {
  border: 0;
  font: 14px Georgia, Serif;
  overflow: hidden;
  resize: none;
  height: 20px;
  width: 100%;
  text-align: left;
  background-color: rgb(227, 241, 245);
}

.table {
  border-collapse: collapse;
}

#documentNumber {
  display: inline;
  float: right;
  text-transform: "uppercase";
  font: "16px Helvetica, Sans-Serif";
  color: rgb(117, 8, 8);
  font-weight: 700;
  letter-spacing: 2px;
  padding: "0 0 8px";
}

.delete-wpr {
  position: relative;
}
.delete {
  display: block;
  color: #000;
  text-decoration: none;
  position: absolute;
  background: #eee;
  font-weight: 700;
  padding: 0 3px;
  border: 1px solid;
  top: -6px;
  left: -22px;
  font-family: Verdana;
  font-size: 12px;
}

#textarea:hover,
#textarea:focus,
#textareaFat:hover,
#textareaFat:focus,
.delete:hover {
  background-color: #ef8;
}
