.pdfRoot {
  background-color: white;
  margin-top: 100px;
  width: 900px;
  border: 1px solid black;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 6fr 6fr;
}
.pdfHeader {
  padding: 5px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-bottom: 1px black solid;
}
.documentHeading {
  margin-top: 30px;
  text-transform: uppercase;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 3px;
}

.pdfLogo {
  justify-self: end;
  padding: 10px;
}
.pdfLogo > img {
  width: 250px;
}

.row1 {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.row2 {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.h1Class {
  padding: 10px;
  font-family: "BauerBodoniW01-BoldDisp", georgia, serif;
  font-size: 2rem;
  color: #6e8e21;
  margin: 0;
  line-height: 1.1em;
}

h1.harvesting {
  color: #aa5734;
}

h1.tomato {
  color: #f23628;
}

h1.capcicum {
  color: green;
}

h1.ladyfinger {
  color: green;
}

h1.apples {
  color: #a13a45;
}

h1.blackberry {
  color: #214095;
}

.orange h1 {
  color: #ffa64a;
}

.plum h1 {
  color: #a51029;
}

.imgClass {
  width: "40%";
  width: 38vw;
  float: left;
  margin: 1em;
}
h3.danger {
  color: red;
}

p.highlight {
  background-color: #ffff00;
  font-size: small;
}
p.boldandsmall {
  color: darkblue;
  font-weight: bold;
  font-size: small;
}

img.right {
  float: right;
}
img.square {
  border-radius: 5%;
}

img.tomato {
  margin-bottom: 0;
  -webkit-shape-outside: circle();
  shape-outside: circle();
}

img.egg {
  -webkit-shape-outside: ellipse();
  shape-outside: ellipse();
}

img.harvesting {
  -webkit-shape-outside: circle();
  shape-outside: circle();
}

img.grapes {
  width: 50vw;
  -webkit-shape-outside: polygon(
    nonzero,
    72.35% 83.95%,
    45.5% 94.3%,
    0% 100%,
    0% 88.4%,
    0% 23.7%,
    38.7% 11.35%,
    55% 11.1%,
    63.5% 22.7%,
    72.15% 20.75%,
    79.1% 30.6%,
    79.8% 34.55%,
    87.6% 43.95%,
    83.7% 57.3%,
    89.15% 65.7%,
    92.55% 72.1%,
    91.15% 83.2%
  );
  shape-outside: polygon(
    nonzero,
    72.35% 83.95%,
    45.5% 94.3%,
    0% 100%,
    0% 88.4%,
    0% 23.7%,
    38.7% 11.35%,
    55% 11.1%,
    63.5% 22.7%,
    72.15% 20.75%,
    79.1% 30.6%,
    79.8% 34.55%,
    87.6% 43.95%,
    83.7% 57.3%,
    89.15% 65.7%,
    92.55% 72.1%,
    91.15% 83.2%
  );
}

img.blackberry {
  width: 40vw;
  -webkit-shape-outside: attr(src url);
  shape-outside: attr(src url);
  -webkit-shape-margin: 0.8em;
  shape-margin: 0.8em;
  -webkit-shape-image-threshold: 0.5;
  shape-image-threshold: 0.5;
}

.orange article {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}
.orange h1 {
  -webkit-column-span: all;
  -moz-column-span: all;
  column-span: all;
  margin-bottom: 1.5rem;
}
.orange p {
  margin-top: 0;
  margin-left: 0;
}
.orange img {
  -webkit-shape-outside: circle();
  shape-outside: circle();
  width: 50%;
  float: left;
  margin: 0 1em 1em 0;
}
.orange img.second {
  width: 300px;
  margin-left: -150px;
}

.plum {
  width: 800px;
}
.plum h1 {
  margin-bottom: 2rem;
}
.plum img {
  width: 72px;
  float: none;
  margin: 0;
  border-radius: 50%;
}
.plum .big {
  -webkit-shape-outside: polygon(
    nonzero,
    -0.4% 20.3%,
    5.2% 19.25%,
    22.2% 37.8%,
    60.2% 55.35%,
    79.8% 70.5%,
    64.2% 81.4%,
    41.4% 81.6%,
    30.8% 91.25%,
    28% 99.8%,
    0% 99.55%
  );
  shape-outside: polygon(
    nonzero,
    -0.4% 20.3%,
    5.2% 19.25%,
    22.2% 37.8%,
    60.2% 55.35%,
    79.8% 70.5%,
    64.2% 81.4%,
    41.4% 81.6%,
    30.8% 91.25%,
    28% 99.8%,
    0% 99.55%
  );
  -webkit-shape-margin: 1em;
  shape-margin: 1em;
  width: 50%;
  float: left;
  font-size: 3vw;
  font-size: 2rem;
  margin-top: 100px;
  margin-right: 100px;
}
