.documentHeaderAndAddress {
  width: 100% !important;

  display: grid;
  grid-template-columns: repeat(8, minmax(min-content, 14rem));
  display: flex;
  justify-content: space-between;
}

.rhs {
  border: 1px solid black;
}
.rhs__grid {
  display: flex;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 120px 160px;
  border-bottom: 1px solid black;
}

.rhs_heading {
  text-align: left !important;
  padding: 5px !important;
  border-right: 1px solid black;
}

.rhs_data {
  width: 100%;
  height: 100%;
  padding-top: 0px;
  padding-right: 2px;
  align-self: center;
  justify-self: end;
}

.rhs_data > select,
input {
  border: none;
  width: 100%;
  height: 100%;
}
