.dtTaxesFlexBox {
  display: flex;
  justify-content: space-between;
}

.dtTaxTable {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 12px;
  padding: 5px;
  border-top: 1px solid black;
}

.dtCell {
  padding: 5px;

  border-bottom: 1px solid black;

  display: flex;
  justify-content: center;
  align-items: center;
}

.dtHeading {
  border-right: 1px solid black;
  font-weight: 600;
}

.dtValue {
  background-color: #c6ddeb;
  font-size: small;
}

.dtCommentsGrid {
  margin-top: 20px;
  padding-left: 100px;
}
